.article-editor-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: 20px;
  background: #f4f4f4;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

.article-editor-container h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
}

.article-editor-select-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.article-editor-select-container label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
}

.article-editor-select-container select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  background: #f9f9f9;
}

/* Поле для ввода заголовка */
.article-editor-input {
  width: 100%;
  padding: 12px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 18px;
}

/* Поле загрузки файла */
.article-editor-image-upload input[type="file"] {
  display: block;
  margin: 10px 0;
}

/* Контейнер редактора */
#editor {
  flex-grow: 1;
  min-height: 400px;
  border-radius: 5px;
  border: 1px solid #ddd;
  background: #fff;
  overflow: auto;
}

/* Контейнер для изображения */
.article-editor-image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 100%;
  margin: 10px auto;
}

/* Превью изображения */
.article-editor-image-preview img {
  max-width: 100%;
  height: auto;
  cursor: nwse-resize;
  border-radius: 5px;
  border: 1px solid #ddd;
}

/* Кнопка удаления изображения */
.article-editor-remove-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(255, 0, 0, 0.7);
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.article-editor-remove-button:hover {
  background: rgba(200, 0, 0, 0.9);
}

/* Кнопки управления */
.article-editor-button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.article-editor-button-container button {
  flex: 1;
  padding: 12px;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin: 5px;
}

.article-editor-save-button {
  background: #007bff;
}

.article-editor-save-button:hover {
  background: #0056b3;
}

.article-editor-delete-button {
  background: #ff4d4d;
}

.article-editor-delete-button:hover {
  background: #cc0000;
}